import { ref, watch, computed } from '@vue/composition-api'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import store from '@/store'
import router from '@/router'

export default function useUsersList() {
  // Use toast
  const toast = useToast()
  const { t } = useI18nUtils()

  const refUserListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'id', label: 'id', sortable: true },
    { key: 'user', label: 'student name', sortable: true },
    { key: 'dob', label: 'birth date' },
    { key: 'phone', label: t('phone') },
    { key: 'country', label: t('country & city'), sortable: true },
    { key: 'comments', label: t('notes') },
    { key: 'actions', label: t('actions'), tdClass: 'table-actions' },
  ]
  const tableColumns1 = [
    { key: 'id', label: 'id', sortable: true },
    { key: 'user', label: t('user'), sortable: true },
    // { key: 'email', label: t('email'), sortable: true },
    { key: 'phone', label: t('phone') },
    { key: 'country', label: t('country & city'), sortable: true },
    { key: 'actions', label: t('actions'), tdClass: 'table-actions' },
  ]
  const perPage = ref(10)
  const totalUsers = ref(0)
  const renamingRequests = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const phone = ref(null)
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)
  const initialDate = ref(null)
  const dob = ref('')
  /* eslint-disable camelcase */
  const search_dob = ref(null)
  const role_name = ref(window.location.href.split('/')[6])
  const selectedFilter = ref({ label: 'Active', value: 'Active' })
  const filters = ref([
    { label: 'All', value: null },
    { label: 'Active', value: 'Active' },
    { label: 'Requested renaming', value: 'requested_naming' },
    { label: 'Deleted', value: 'Deleted' },
  ])
  if (router.currentRoute.params.value) {
    selectedFilter.value = router.currentRoute.params
  }

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    }
  })
  const isBusy = ref(false)
  const refetchData = () => {
    refUserListTable.value.refresh()
  }
  const urlParams = ref(new URLSearchParams())
  if (router.currentRoute.query.search) searchQuery.value = router.currentRoute.query.search
  if (router.currentRoute.query.page) currentPage.value = router.currentRoute.query.page
  if (router.currentRoute.query.perPage) perPage.value = router.currentRoute.query.perPage
  if (router.currentRoute.query.dob) { dob.value = router.currentRoute.query.dob; initialDate.value = `${dob.value.split('-')[2]}/${dob.value.split('-')[1]}/${dob.value.split('-')[0]}` }
  if (router.currentRoute.query.filter) selectedFilter.value = router.currentRoute.query.filter !== 'all' ? filters.value.find(el => el.value === router.currentRoute.query.filter) : filters.value.find(el => !el.value)
  const recheckLoading = () => {
    if (!isBusy.value) return refetchData()
    return setTimeout(() => recheckLoading(), 1000)
  }
  /* eslint-disable-next-line */
  watch([currentPage, perPage, phone, roleFilter, planFilter, statusFilter, role_name, selectedFilter, dob],  () => {
    urlParams.value.set('?search', searchQuery.value ? searchQuery.value : '')
    urlParams.value.set('dob', dob.value ? dob.value : '')
    urlParams.value.set('page', currentPage.value ? currentPage.value : '')
    urlParams.value.set('perPage', perPage.value ? perPage.value : '')
    urlParams.value.set('filter', selectedFilter.value.value ? selectedFilter.value.value : 'all')
    window.history.replaceState(null, null, decodeURIComponent(urlParams.value))
    recheckLoading()
  })
  watch(initialDate, () => {
    setTimeout(() => {
      // Check whether the date is fully typed and is valid
      if (initialDate.value === '') {
        dob.value = null
      }
      if ((initialDate.value.length === 10 && document.getElementById('dobInput').classList.contains('is-valid'))) {
        // Reformate date
        dob.value = `${initialDate.value.split('/')[2]}-${initialDate.value.split('/')[1]}-${initialDate.value.split('/')[0]}`
      }
    }, 500)
  })
  watch(searchQuery, () => {
    urlParams.value.set('?search', searchQuery.value ? searchQuery.value : '')
    window.history.replaceState(null, null, decodeURIComponent(urlParams.value))
    refetchData()
  })

  const fetchUsers = (ctx, callback) => {
    store
      .dispatch('app-user/fetchUsers', {
        search: searchQuery.value,
        phone: phone.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        role_name: roleFilter.value,
        filter: selectedFilter.value.value,
        search_dob: dob.value,
        // role_name: role_name.value,
      })
      .then(() => {
        const users = store.state.userStoreModule.users.rows
        const total = store.state.userStoreModule.users.totalRecords
        callback(users)
        totalUsers.value = total
        if (store.state.userStoreModule.users.rows.length > 0) {
          renamingRequests.value = store.state.userStoreModule.users.rows[0].name_request_number
        } else {
          renamingRequests.value = 0
        }
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching users list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'danger'
    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'subscriber') return 'UserIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'admin') return 'ServerIcon'
    return 'UserIcon'
  }

  const resolveUserStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'secondary'
    return 'primary'
  }

  return {
    isBusy,
    dob,
    initialDate,
    fetchUsers,
    tableColumns,
    tableColumns1,
    perPage,
    currentPage,
    totalUsers,
    renamingRequests,
    dataMeta,
    perPageOptions,
    searchQuery,
    phone,
    sortBy,
    isSortDirDesc,
    refUserListTable,
    role_name,
    search_dob,

    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    refetchData,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
    selectedFilter,
    filters,
  }
}
